import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Snackbar,
  Alert,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio
} from '@mui/material';
import { useParams } from 'react-router-dom';

const UpdateClientForm = () => {
  const { customerNumber } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formData, setFormData] = useState({
    customer_number: '',
    customer_name: '',
    running_balance: '',
    sequence: '3', // Default to weekly
    email_address: '',
    ready: false,
    sent: false,
    follow_up: false,
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the client data and populate the form
    const fetchClientData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/statement/get_client/${customerNumber}`,
          {
            headers: {
              'X-Dispatch-Key':
                'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj',
            },
          }
        );
        if (response.status === 200) {
          setFormData(response.data);
        } else {
          throw new Error(`Unexpected response code: ${response.status}`);
        }
      } catch (error) {
        console.error('There was an error fetching the client data!', error);
        setError('Failed to fetch client data');
      }
    };

    fetchClientData();
  }, [customerNumber]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/statement/update_client/${customerNumber}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Dispatch-Key':
              'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj',
          },
        }
      );
      if (response.status === 200) {
        console.log('Client updated:', response.data);
        // alert(response.data.customer_name+' as été mis à jour.');
        setError(null); // Clear any previous error
        setSnackbarOpen(true); // Open the Snackbar
        // Handle success (e.g., show a success message, navigate to another page, etc.)
      } else {
        throw new Error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        setError('There was an error updating the client!');
      }
      console.error('There was an error updating the client!', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClose = () => {
    setError(null);
  };

  return (
    <Container style={{ marginTop: '30px', width: '60%' }}>
      <Typography variant='h4' gutterBottom style={{ marginBottom: '50px' }}>
        Update Client Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Customer Number'
              name='customer_number'
              value={formData.customer_number}
              onChange={handleChange}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Customer Name'
              name='customer_name'
              value={formData.customer_name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Running Balance'
              name='running_balance'
              value={formData.running_balance}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Email Address'
              name='email_address'
              value={formData.email_address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sequence</FormLabel>
              <RadioGroup
                aria-label="sequence"
                name="sequence"
                value={formData.sequence}
                onChange={handleChange}
                style={{ marginBottom: '30px' }}
                row
              >
                <FormControlLabel value="1" control={<Radio />} label="Monthly" />
                <FormControlLabel value="2" control={<Radio />} label="Biweekly" />
                <FormControlLabel value="3" control={<Radio />} label="Weekly" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' type='submit'>
              Update Client
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {formData.customer_name} a été mis à jour.
        </Alert>
      </Snackbar>
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity='error'>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UpdateClientForm;
