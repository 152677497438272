import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { login, logout } from '../../redux/authSlice';
/**
 * Login component
 *
 * Handles user login functionality
 *
 * @returns {JSX.Element} The login form component
 */
const Login = () => {
  /**
   * State variables for username, password, error, and message
   */
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  /**
   * Navigate function from react-router-dom
   */
  const navigate = useNavigate();

  /**
   * Dispatch function from react-redux
   */
  const dispatch = useDispatch();

  /**
   * Handle submit function for login form
   *
   * @param {Event} event The submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    const loginData = {
      username,
      password,
    };

    try {
      /**
       * Make API call to login endpoint
       *
       * @example
       * const response = await axios.post("${process.env.REACT_APP_API_URL}/register/loginStatement", {
       *   username: 'johnDoe',
       *   password: 'password123'
       * });
       */
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register/loginStatement`,
        loginData
      );
      const { token } = response.data;
      dispatch(login(token));
      setMessage('Connexion Réussi!');
      setError('');
      navigate('/main');
    } catch (err) {
      console.error(err);
      setError("Nom d'utilisateur ou mot de passe incorrect.");
      setMessage('');
    }
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100vh'
      textAlign='center'>
      <Container maxWidth='sm'>
        <Box mt={8}>
          <Typography variant='h2' component='h1' gutterBottom color='primary'>
            Login
          </Typography>
          {error && <Alert severity='error'>{error}</Alert>}
          {message && <Alert severity='success'>{message}</Alert>}
          <form onSubmit={handleSubmit}>
            <Box mb={2} width='100%'>
              <TextField
                fullWidth
                id='username'
                label='Username'
                variant='outlined'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Box>
            <Box mb={2} width='100%'>
              <TextField
                fullWidth
                id='password'
                label='Password'
                type='password'
                variant='outlined'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Button variant='contained' color='primary' type='submit' fullWidth>
              Login
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
