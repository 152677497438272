import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Snackbar,
  Alert,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio 
} from '@mui/material';

/**
 * FormCreate component
 *
 * @example
 * <FormCreate />
 */
const FormCreate = () => {
  const [formData, setFormData] = useState({
    customer_number: '',
    customer_name: '',
    running_balance: '',
    sequence: '3',  // Default to 'weekly'
    email_address: '',
    ready: false,
    sent: false,
    follow_up: false,
  });
  const [error, setError] = useState(null);

  /**
   * Handles changes to the form data
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event
   */
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  /**
   * Handles form submission
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form submission event
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/statement/create_client`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Dispatch-Key':
              'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj',
          },
        }
      );
      console.log('Client created:', response.data);
      setFormData({
        customer_number: '',
        customer_name: '',
        running_balance: '',
        sequence: '',
        email_address: '',
        ready: false,
        sent: false,
        follow_up: false,
      });
      setError(null);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        setError('There was an error creating the client!');
      }
      console.error('There was an error creating the client!', error);
    }
  };

  const handleClose = () => {
    setError(null);
  };

  return (
    <Container style={{ marginTop: '40px', width: '60%' }}>
      <Typography
        variant='h4'
        gutterBottom
        style={{ marginBottom: '50px', textAlign: 'center' }}>
        Formulaire de création de client
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Customer Number'
              name='customer_number'
              value={formData.customer_number}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Customer Name'
              name='customer_name'
              value={formData.customer_name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Running Balance'
              name='running_balance'
              value={formData.running_balance}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Email Address'
              name='email_address'
              value={formData.email_address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sequence</FormLabel>
              <RadioGroup
                aria-label="sequence"
                name="sequence"
                value={formData.sequence}
                onChange={handleChange}
                row
                style={{ marginBottom: '30px' }}
              >
                <FormControlLabel value="1" control={<Radio />} label="Monthly" />
                <FormControlLabel value="2" control={<Radio />} label="Biweekly" />
                <FormControlLabel value="3" control={<Radio />} label="Weekly" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' type='submit'>
              Create Client
            </Button>
          </Grid>
        </Grid>
      </form>
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity='error'>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default FormCreate;
