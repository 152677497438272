/**
 * Creates a slice of the Redux state for authentication.
 *
 * @module authSlice
 */
import { createSlice } from '@reduxjs/toolkit';

/**
 * The authentication slice.
 *
 * @typedef {Object} authSlice
 * @property {boolean} isAuthenticated - Whether the user is authenticated or not.
 */
const authSlice = createSlice({
  /**
   * The name of the slice.
   *
   * @type {string}
   */
  name: 'auth',
  /**
   * The initial state of the slice.
   *
   * @type {Object}
   */
  initialState: {
    /**
     * Whether the user is authenticated or not.
     *
     * @type {boolean}
     */
    isAuthenticated: !!localStorage.getItem('token'),
  },
  /**
   * Reducers for the slice.
   *
   * @type {Object}
   */
  reducers: {
    /**
     * Logs the user in.
     *
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action payload.
     * @param {string} action.payload - The authentication token.
     */
    login(state, action) {
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload);
    },
    /**
     * Logs the user out.
     *
     * @param {Object} state - The current state of the slice.
     */
    logout(state) {
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
  },
});

/**
 * Exports the login action creator.
 *
 * @example
 * import { login } from './authSlice';
 * login('myAuthToken');
 *
 * @type {Function}
 */
export const { login } = authSlice.actions;

/**
 * Exports the logout action creator.
 *
 * @example
 * import { logout } from './authSlice';
 * logout();
 *
 * @type {Function}
 */
export const { logout } = authSlice.actions;

/**
 * Exports the reducer function.
 *
 * @example
 * import { combineReducers, createStore } from 'redux';
 * import authReducer from './authSlice';
 *
 * const rootReducer = combineReducers({ auth: authReducer });
 * const store = createStore(rootReducer);
 *
 * @type {Function}
 */
export default authSlice.reducer;
