import React from 'react';
import Customers from '../Customers/Customers';

const App = () => {
  return (
    <div>
      <Customers />
    </div>
  );
};

export default App;
