import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './views/Login/Login';
import MainPage from './views/MainPage/Main';
import Templates from './views/Templates/Templates';
import NavBar from './components/Navbar/NavBar';
import PrivateRoute from './PrivateRoute';
import Customers from './views/Customers/Customers';
import FormCreate from './views/FormClient/FormCreate';
import FormUpdate from './views/FormClient/FormUpdate';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route
          path='/customers'
          element={
            <PrivateRoute>
              <Customers />
            </PrivateRoute>
          }
        />
        <Route
          path='/main'
          element={
            <PrivateRoute>
              <MainPage />
            </PrivateRoute>
          }
        />
        <Route
          path='/templates'
          element={
            <PrivateRoute>
              <Templates />
            </PrivateRoute>
          }
        />
        <Route
          path='/create'
          element={
            <PrivateRoute>
              <FormCreate />
            </PrivateRoute>
          }
        />
        <Route path='/update-client/:customerNumber' element={<FormUpdate />} />
        <Route path='/' element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
