import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * A private route component that redirects to the login page if the user is not authenticated.
 *
 * @param {ReactNode} children - The component to render if the user is authenticated.
 * @returns {ReactNode} The authenticated component or a redirect to the login page.
 *
 * Example:
 * ```
 * import React from 'eact';
 * import PrivateRoute from './PrivateRoute';
 *
 * const MyProtectedPage = () => {
 *   return <h1>Welcome to the protected page!</h1>;
 * };
 *
 * const App = () => {
 *   return (
 *     <Routes>
 *       <Route path="/protected" element={
 *         <PrivateRoute>
 *           <MyProtectedPage />
 *         </PrivateRoute>
 *       } />
 *       <Route path="/login" element={<LoginPage />} />
 *     </Routes>
 *   );
 * };
 * ```
 */

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to='/login' />;
};

export default PrivateRoute;
