import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../components/CustomDataGrid/CustomDataGrid.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  Skeleton 
} from '@mui/material';
import PropTypes from 'prop-types';

const emailTemplates = {
  followup: 'FollowupTemplate.html',
  statement: 'StatementTemplate.html',
};

const sequenceMapping = {
  '1': 'Mensuel',
  '2': 'Quinzomadaire',
  '3': 'Hebdomadaire',
};


const Customers = () => {
  const [customerData, setCustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterParameters, setFilterParameters] = useState({
    weekly: false,   // sequence 3
    biweekly: false, // sequence 2
    monthly: false,  // sequence 1
  });

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setFilterParameters((prev) => ({ ...prev, [name]: checked }));
  };

  const handleApplyFilters = () => {
    setFilteredData(
      customerData.filter((customer) =>
        (filterParameters.weekly && customer.sequence === 'Hebdomadaire') ||
        (filterParameters.biweekly && customer.sequence === 'Quinzomadaire') ||
        (filterParameters.monthly && customer.sequence === 'Mensuel')
      )
    );
    setFiltersOpen(false);
  };
  

  const emailSendingRef = useRef(false);
  const pageSize = 1000;
  const navigate = useNavigate();

  /**
   * Filter data based on search query
   */
  useEffect(() => {
    setFilteredData(
      customerData.filter((customer) =>
        Object.values(customer).some((value) =>
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    );
  }, [searchQuery, customerData]);

  /**
   * Handle run script button click
   *
   * Runs the script, fetches customer data, and updates the component state.
   */
  const handleRunScript = async () => {
    setLoading(true);
    try {
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/run-script`);
      if (response.status !== 200) {
        throw new Error('Failed to run the script');
      }
      alert(response.data.message);
  
      response = await axios.get(`${process.env.REACT_APP_API_URL}/fetch-customer-data`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch customer data');
      }
      const customerData = response.data.map(customer => ({
        ...customer,
        sequence: sequenceMapping[customer.sequence],
      }));
  
      const filteredData = customerData
        .filter((customer) => parseFloat(customer.running_balance) > 0)
        .sort(
          (a, b) =>
            parseFloat(b.running_balance.replace(/,/g, '')) -
            parseFloat(a.running_balance.replace(/,/g, ''))
        );
  
      setCustomerData(filteredData);
      setFilteredData(filteredData);
      if (filteredData.length > 0) {
        setColumns(Object.keys(filteredData[0]));
      }
      setIsLoaded(true);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to run the script or fetch data.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle checkbox change
   *
   * Updates the selected rows state based on the checkbox change.
   *
   * @param {Event} event The checkbox change event
   * @param {Object} row The customer data row
   */
  const handleCheckboxChange = (event, row) => {
    setSelectedRows((prevSelected) =>
      event.target.checked
        ? [...prevSelected, row]
        : prevSelected.filter((selectedRow) => selectedRow !== row)
    );
  };

  /**
   * Handle select all change
   *
   * Updates the selected rows state based on the select all checkbox change.
   *
   * @param {Event} event The select all checkbox change event
   */
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(currentRows);
    } else {
      setSelectedRows([]);
    }
  };

  /**
   * Send email to selected customers
   *
   * Sends an email to each selected customer using the specified template.
   *
   * @param {string} templateName The email template name
   */
  const sendEmail = useCallback(
    async (templateName) => {
      if (emailLoading || emailSendingRef.current) return;

      emailSendingRef.current = true;

      const templateFile = emailTemplates[templateName];

      if (!templateFile) {
        emailSendingRef.current = false;
        return;
      }

      setEmailLoading(true);
      const emailPromises = selectedRows.map(async (row) => {
        const payload = {
          client_number: row.customer_number,
          client_name: row.customer_name,
          to: row.email_address,
          template: templateFile,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/send_email`,
            payload
          );

          setCustomerData((prevData) =>
            prevData.map((customer) =>
              customer.customer_number === row.customer_number
                ? {
                    ...customer,
                    sent: templateName === 'statement' ? true : customer.sent,
                    follow_up:
                      templateName === 'followup' ? true : customer.follow_up,
                  }
                : customer
            )
          );

          return response;
        } catch (error) {
          console.error(
            'Failed to send email for:',
            row.customer_number,
            error
          );
          throw error;
        }
      });

      try {
        await Promise.all(emailPromises);
        console.log('Emails sent successfully.');
        setRefresh((prev) => !prev);
        alert('Emails sent to selected rows.');
        setSelectedRows([]); // Uncheck all checkboxes after sending emails

        // Force re-render by updating customer data to trigger UI update
        setCustomerData((prevData) => [...prevData]);
      } catch (error) {
        console.error(
          'Failed to send emails:',
          error.response ? error.response.data : error
        );
      } finally {
        setEmailLoading(false);
        emailSendingRef.current = false; // Reset the flag
      }
    },
    [selectedRows, emailLoading, setCustomerData, setRefresh]
  );
  /**
   * Renders a boolean cell with a green checkmark (✓) if the value is true, and a red cross (✗) if the value is false.
   *
   * @param {boolean} value The boolean value to render
   * @returns {ReactElement} A React element representing the boolean cell
   * @example
   * const boolValue = true;
   * const renderedCell = renderBooleanCell(boolValue);
   * // renderedCell: <span style={{ color: 'green' }}>✓</span>
   */
  const renderBooleanCell = (value) => {
    return value ? (
      <span style={{ color: 'green' }}>✓</span>
    ) : (
      <span style={{ color: 'red' }}>✗</span>
    );
  };

  const currentRows = filteredData.slice(
    page * pageSize,
    (page + 1) * pageSize
  );

  const allSelected =
    currentRows.length > 0 && selectedRows.length === currentRows.length;

  const handleEditClick = (customerNumber) => {
    navigate(`/update-client/${customerNumber}`);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>ÉTATS DE COMPTE CLIENT</h1>
      <input
        type='text'
        placeholder='Recherche...'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          width: '50%',
          padding: '10px',
          marginBottom: '20px',
          fontSize: '16px',
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}>
        <Button
          onClick={handleRunScript}
          variant='contained'
          color='primary'
          style={{ marginRight: '10px' }}>
          Run Script and Load Data
        </Button>
        {loading && <CircularProgress />}
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <Button
          variant='contained'
          color='primary'
          style={{ marginLeft: '20px' }}
          onClick={() => sendEmail('statement')}
          disabled={selectedRows.length === 0 || emailLoading}>
          Send Statement Email
        </Button>
        <Button
          variant='contained'
          color='secondary'
          style={{ marginLeft: '20px' }}
          onClick={() => sendEmail('followup')}
          disabled={selectedRows.length === 0 || emailLoading}>
          Send Followup Email
        </Button>
        <Button
          variant='contained'
          color='secondary'
          style={{ marginLeft: '20px' }}
          onClick={() => setFiltersOpen(true)}
          disabled={emailLoading}>
          filtres
        </Button>
        {/*** FILTERS START ***/}
        <Dialog open={filtersOpen} onClose={() => setFiltersOpen(false)}>
          <DialogTitle>Apply Filters</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterParameters.monthly}
                  onChange={handleFilterChange}
                  name="monthly"
                />
              }
              label="Mensuel"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterParameters.biweekly}
                  onChange={handleFilterChange}
                  name="biweekly"
                />
              }
              label="Bihebdomadaire"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterParameters.weekly}
                  onChange={handleFilterChange}
                  name="weekly"
                />
              }
              label="Hebdomadaire"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFiltersOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleApplyFilters} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
        {/*** FILTERS END ***/}
      </div>
      {!loading && !error && isLoaded && (
        <>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    checked={allSelected}
                    onChange={handleSelectAllChange}
                  />
                </th>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((customer, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <input
                      type='checkbox'
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow === customer
                      )}
                      onChange={(event) =>
                        handleCheckboxChange(event, customer)
                      }
                    />
                  </td>
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ border: '1px solid #ddd', padding: '8px' }}>
                      {['ready', 'sent', 'follow_up'].includes(column)
                        ? renderBooleanCell(customer[column])
                        : customer[column]}
                    </td>
                  ))}
                  <td>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleEditClick(customer.customer_number)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: '20px' }}>
            <Button
              variant='contained'
              color='primary'
              disabled={page === 0}
              onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}>
              Previous
            </Button>
            <span style={{ margin: '0 10px' }}>Page {page + 1}</span>
            <Button
              variant='contained'
              color='primary'
              disabled={(page + 1) * pageSize >= filteredData.length}
              onClick={() => setPage((prevPage) => prevPage + 1)}>
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

Customers.propTypes = {
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
  page: PropTypes.number,
  sortConfig: PropTypes.object,
  refresh: PropTypes.bool,
  searchQuery: PropTypes.string,
};

export default Customers;
  