import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { Button, Container, Grid, Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

/**
 * A styled Container component with padding top and bottom.
 *
 * @param {Object} theme - The theme object from Material-UI.
 * @returns {Object} - A styled Container component.
 */
const ContainerStyled = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

/**
 * A styled Paper component with padding, display, and overflow properties.
 *
 * @param {Object} theme - The theme object from Material-UI.
 * @returns {Object} - A styled Paper component.
 */
const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
}));

/**
 * A styled Button component with margin.
 *
 * @param {Object} theme - The theme object from Material-UI.
 * @returns {Object} - A styled Button component.
 */
const ButtonStyled = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

/**
 * Handles changes to the HTML editor content.
 *
 * @param {string} content - The new HTML content.
 * @param {Object} delta - The delta object from the editor.
 * @param {string} source - The source of the change.
 * @param {Object} editor - The editor instance.
 */
const Templates = () => {
  const [html, setHtml] = useState('');
  const [fileName, setFileName] = useState('');

  const handleChange = (content, delta, source, editor) => {
    setHtml(editor.getHTML());
  };

  const fetchHtmlFile = (name) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/fetch-html?fileName=${name}`)
      .then((response) => {
        setHtml(response.data.html);
        setFileName(name);
      })
      .catch((error) => {
        console.error('There was an error fetching the file!', error);
      });
  };

  const saveHtml = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/save-html`, {
        html,
        fileName,
      })
      .then((response) => {
        alert('File saved successfully');
      })
      .catch((error) => {
        console.error('There was an error saving the file!', error);
      });
  };

  return (
    <ContainerStyled maxWidth='lg'>
      <Typography variant='h4' gutterBottom>
        Template Editor
      </Typography>
      <PaperStyled>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ButtonStyled
              variant='contained'
              color='primary'
              onClick={() => fetchHtmlFile('FollowupTemplate.html')}>
              Followup Template
            </ButtonStyled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonStyled
              variant='contained'
              color='primary'
              onClick={() => fetchHtmlFile('StatementTemplate.html')}>
              Statement Template
            </ButtonStyled>
          </Grid>
        </Grid>
        <Box mt={2}>
          <ReactQuill value={html} onChange={handleChange} />
        </Box>
        <ButtonStyled variant='contained' color='secondary' onClick={saveHtml}>
          Save
        </ButtonStyled>
      </PaperStyled>
    </ContainerStyled>
  );
};

export default Templates;
