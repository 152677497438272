/**
 * A navigation bar component for the Super Statement application.
 *
 * This component renders an AppBar with a logo, navigation links, and a logout button.
 *
 * @returns {React.ReactElement} The navigation bar component.
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../redux/authSlice';
import { styled } from '@mui/material/styles';

/**
 * A custom button component for navigation links.
 *
 * @example
 * <NavBarButton component={Link} to='/main'>
 *   Envoi d'état de compte
 * </NavBarButton>
 */
const NavBarButton = styled(Button)({
  color: 'inherit',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    color: '#003ca6',
  },
});

/**
 * A custom button component for the logout button.
 *
 * @example
 * <LogoutButton onClick={handleLogout}>Log out</LogoutButton>
 */
const LogoutButton = styled(Button)({
  color: '#fff',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: 'ff6666',
    color: '#FFF',
  },
});

/**
 * The navigation bar component.
 *
 * @returns {React.ReactElement} The navigation bar component.
 */
const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Handles the logout action.
   *
   * Clears the authentication token from local storage and dispatches the logout action.
   */
  const handleLogout = () => {
    // Clear any authentication tokens or state
    localStorage.removeItem('token'); // Adjust this if you're storing the token differently
    dispatch(logout());
    navigate('/');
  };

  return (
    <AppBar position='static' style={{ backgroundColor: '#003ca6' }}>
      <Toolbar>
        <Typography variant='h4'>Super</Typography>
        <Typography
          variant='h4'
          component='div'
          sx={{ flexGrow: 1 }}
          fontWeight='fontWeightBold'>
          Statement
        </Typography>
        <Box style={{ display: 'flex', gap: '1rem' }}>
          <NavBarButton component={Link} to='/customers'>
            Clients
          </NavBarButton>
          <NavBarButton component={Link} to='/templates'>
            Templates E-mail
          </NavBarButton>
          <NavBarButton component={Link} to='/create'>
            Creation client
          </NavBarButton>
          <LogoutButton onClick={handleLogout}>Log out</LogoutButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
