/**
 * Creates a Redux store with the auth reducer.
 *
 * The store is configured using the `configureStore` function from `@reduxjs/toolkit`.
 * It takes an object with a single property `reducer`, which is an object that maps
 * the `auth` key to the `authReducer` function.
 *
 * @example
 * import store from './store';
 * import { login } from './authSlice';
 *
 * store.dispatch(login('username', 'password'));
 * console.log(store.getState().auth); // { isLoggedIn: true, username: 'username' }
 *
 * @returns {Store} The configured Redux store.
 */
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

export default store;
